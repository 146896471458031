import PropTypes from 'prop-types'
import Head from 'next/head'
import LOCALES from '../featureLocales'

const LocalisedLinks = ({ links, original }) => (
  <Head>
    <link
      rel="alternate"
      hrefLang={original.locale}
      href={`${LOCALES[original.locale].webAddress}${original.contentUrl}`}
      key={`localised-link-original-${original.contentUrl}`}
    />
    {links.map((link) => (
      <link
        rel="alternate"
        hrefLang={LOCALES[link.locale].iso}
        href={`${LOCALES[link.locale].webAddress}${link.contentUrl}`}
        key={`localised-link-${link.contentUrl}`}
      />
    ))}
  </Head>
)

LocalisedLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string.isRequired,
      locale: PropTypes.oneOf(Object.keys(LOCALES)).isRequired,
    })
  ).isRequired,
  original: PropTypes.shape({
    contentUrl: PropTypes.string.isRequired,
    locale: PropTypes.oneOf(Object.keys(LOCALES)).isRequired,
  }).isRequired,
}

export default LocalisedLinks
