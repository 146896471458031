import { useState } from 'react'
import PropTypes from 'prop-types'
import { NewsArticleJsonLd, VideoJsonLd } from 'components/generic/json-ld'
import {
  useQueryResult,
  DefaultLoadingSpinner,
} from 'components/generic/query-result-handler'
import FeatureHeader, {
  getFeatureHeaderInfo,
} from 'components/editorial/features/feature-header'
import usePageviewTracking, { PageTypes } from 'hooks/usePageviewTracking'
import useEditorialTracking from 'hooks/useEditorialTracking'
import AD_TARGETING_KEYS from 'enums/ad-targeting-keys'
import ENTITY_TYPE from 'enums/entity-type'
import dynamic from 'next/dynamic'
import Throw404 from 'components/generic/throw-404'
import Throw500 from 'components/generic/throw-500'
import InnerHTML from 'dangerously-set-html-content' // Using this as Reacts dangerouslySetInnerHTML does not execute <script> tags
import YoutubePlayer from 'components/generic/youtube-player'
import { useAdTargetingDispatch } from 'hooks/useAdTargeting'
import useViewEditorialMixpanelProperties from 'scripts/tracking/helpers/useViewEditorialMixpanelProperties'
import useLegacyFeaturesJs from 'hooks/useLegacyFeaturesJs'
import TRACKING_EVENT from 'scripts/tracking/tracking-event'
import Tracking from 'scripts/tracking'
import MixpanelEditorialTrackingWrapper from 'components/generic/mixpanel-editorial-tracking-wrapper'
import Seo from 'components/generic/seo/Seo'
import useBuildFeatureDetailSeo, {
  buildKeywords,
} from 'hooks/useBuildFeatureDetailSeo'
import testIds from 'enums/testIds'
import FeatureArticleHeader from './FeatureArticleHeader'
import GET_FEATURE_ARTICLE from './GetFeatureArticleQuery'
import FeatureDetailBar from './feature-detail-bar'
import getFeatureContent from './featureStyleFactory'
import LocalisedFeatureLinks from './localised-feature-links'
import useLazyLoad from './useLazyLoad'

const FeatureRelatedContent = dynamic(
  () => import('./feature-related-content'),
  { ssr: false }
)

const FeatureArticle = ({ id }) => {
  const { data, error, loading, empty } = useQueryResult(GET_FEATURE_ARTICLE, {
    variables: { id },
    dataKey: 'feature',
  })

  const { data: mixpanelProperties } = useViewEditorialMixpanelProperties(data)

  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.viewEditorial,
      properties: mixpanelProperties,
      skip: error || empty,
    },
  })

  if (error) return <Throw500 />
  if (empty) return <Throw404 entityType={ENTITY_TYPE.Feature} />

  return (
    <>
      <FeatureArticleHeader id={id} />
      {loading ? (
        <DefaultLoadingSpinner />
      ) : (
        <FeatureArticleMarkup
          data={data}
          mixpanelProperties={mixpanelProperties}
        />
      )}
    </>
  )
}

FeatureArticle.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const FeatureArticleMarkup = ({ data, mixpanelProperties = {} }) => {
  useLazyLoad()
  usePageviewTracking(PageTypes.Feature, data.id)
  useAdTargetingDispatch(buildAdTargeting(data.contentUrl, data.tech))

  const [headerLoaded, setHeaderLoaded] = useState(false)

  useLegacyFeaturesJs(headerLoaded)

  const featureHeaderInfo = getFeatureHeaderInfo(data)
  useEditorialTracking(data)

  return (
    <>
      <Seo {...useBuildFeatureDetailSeo({ data })} />
      <NewsArticleJsonLd
        contentUrl={data.contentUrl}
        title={data.title}
        images={[getFeatureImage(data, 'STANDARD')]}
        datePublished={data.date}
        authorName={data.author.name}
        description={data.blurb}
        keywords={buildKeywords(data)}
        articleSection="Feature"
      />
      <LocalisedFeatureLinks links={data.localised} original={data} />
      <MixpanelEditorialTrackingWrapper properties={mixpanelProperties}>
        <article id="feature">
          {data.youtubeId ? (
            <div data-testid={testIds.youtubePlayer}>
              <VideoJsonLd
                name={data.title}
                description={data.blurb}
                thumbnails={data.images.map((x) => x.imageUrl)}
                uploadDate={data.date}
                embedUrl={`https://www.youtube.com/embed/${data.youtubeId}`}
              />
              <YoutubePlayer youtubeId={data.youtubeId} />
            </div>
          ) : (
            <FeatureHeader
              title={data.title}
              blurb={data.blurb}
              headerLoadComplete={() => setHeaderLoaded(true)}
              {...featureHeaderInfo}
            />
          )}

          <main>
            <InnerHTML
              html={getFeatureContent(data, featureHeaderInfo.headerType)}
            />
          </main>
        </article>
      </MixpanelEditorialTrackingWrapper>

      <FeatureDetailBar feature={data} />
      <FeatureRelatedContent feature={data} />
    </>
  )
}
const getFeatureImage = (data, type) => {
  const image = data.images.find((x) => x.type === type)

  return image ? image.imageUrl : null
}

FeatureArticleMarkup.propTypes = {
  data: PropTypes.object,
  mixpanelProperties: PropTypes.object,
}

const buildAdTargeting = (path, tech = false) => {
  let result = [
    [AD_TARGETING_KEYS.PAGE, path],
    [AD_TARGETING_KEYS.CATEGORY, 'Magazine'],
    [AD_TARGETING_KEYS.SECTION, 'Features'],
  ]

  if (tech) {
    result = [...result, [AD_TARGETING_KEYS.CONTENT, 'Tech']]
  }

  return result
}

export { FeatureArticleMarkup }
export default FeatureArticle
